
import { Component, Vue } from "vue-property-decorator";

type Credentials = {
  login: string;
  password: string;
  remember?: boolean;
};

const GUEST_CREDENTIALS: Credentials = {
  login: "guest",
  password: "password"
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    LoginForm: () => import("@/app/modules/auth/components/LoginForm.vue")
  }
})
export default class LoginPage extends Vue {
  credentials: Credentials = {
    login: localStorage.getItem("login") ?? "",
    password: localStorage.getItem("password") ?? "",
    remember: !!localStorage.getItem("login") && !!localStorage.getItem("password")
  };

  errors = null;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get loginButtonTitle() {
    return `${this.$t("AUTH.LOGIN")} ${this.appData.name}`;
  }

  getToken({ guest = false }) {
    this.$store
      .dispatch("auth/login", guest ? GUEST_CREDENTIALS : this.credentials)
      .then(({ data }) => {
        this.rememberCredentials();

        this.$store.commit("auth/setApiToken", data.api_token);
        this.$router.push({ name: "home" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  rememberCredentials() {
    if (this.credentials.remember) this.$store.commit("auth/saveCredentials", this.credentials);
    else this.$store.commit("auth/destroyCredentials");
  }
}
